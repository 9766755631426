import {
  type ExperimentsBag,
  type AppManifestBuilder,
  type IHttpClient,
  type useFedopsLogger,
} from '@wix/yoshi-flow-editor';
import { type AppManifest } from '@wix/editor-platform-sdk-types';
import { widgetGfppBuilder } from './gfpp';
import { FORM_APP_WIDGET_ID } from '../../constants/app';
import type { ErrorHandlerPublicAPI } from '@wix/fe-essentials-editor/error-handler';

type FedopsLogger = ReturnType<typeof useFedopsLogger>;

export const getAppManifestFactory =
  (
    getT: Function,
    getAppToken: Function,
    getSdk: Function,
    getExperiments: () => ExperimentsBag,
    getHttpClient: () => IHttpClient,
    getErrorHandler: () => ErrorHandlerPublicAPI,
    getFedopsLogger: () => FedopsLogger,
  ) =>
  async ({
    appManifestBuilder,
  }: {
    appManifestBuilder: AppManifestBuilder;
  }): Promise<AppManifest> => {
    const t = getT();
    const appToken = getAppToken();
    const editorSDK = getSdk();
    const httpClient = getHttpClient();
    const errorHandler = getErrorHandler();
    const fedopsLogger = getFedopsLogger();

    appManifestBuilder = appManifestBuilder.withJsonManifest({});
    appManifestBuilder = await widgetGfppBuilder({
      t,
      editorSDK,
      appToken,
      httpClient,
      errorHandler,
      fedopsLogger,
      getExperiments,
    })(appManifestBuilder);

    appManifestBuilder.configureWidget(FORM_APP_WIDGET_ID, (widgetBuilder) =>
      widgetBuilder.set({ nickname: 'form' }),
    );

    return appManifestBuilder.build();
  };
