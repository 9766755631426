import type { ComponentRef, EditorSDK } from '@wix/editor-platform-sdk-types';
import { getTextInputStyle } from './text-input-remap';
import { getButtonStyle } from './button-remap';
import { getFormContainerStyle } from './form-container-remap';
import { getRichTextStyle } from './rich-text-remap';
import { type Style } from './style-utils';
import { getNextButtonStyle } from './next-button-remap';
import { getPreviousButtonStyle } from './previous-button-remap';

const NEW_FORMS_APP_DEF_ID = '225dd912-7dea-4738-8688-4b8c6955ffc2';
const NEW_FOMRS_WIDGET_ID = '371ee199-389c-4a93-849e-e35b8a15b7ca';

const TOKEN = 'TOKEN';

const OLD_FORMS_APP_WIDGET_TYPE = 'platform.components.AppWidget';
const OLD_FORMS_WIDGET_TYPE = 'wysiwyg.viewer.components.FormContainer';
const STATE_BOX_WIDGET_TYPE = 'wysiwyg.viewer.components.StateBox';
const STATE_BOX_FORM_WIDGET_TYPE =
  'wysiwyg.viewer.components.StateBoxFormState';

enum FORM_ELEMENT_TYPES {
  TEXT_INPUT = 'wysiwyg.viewer.components.inputs.TextInput',
  BUTTON = 'wysiwyg.viewer.components.SiteButton',
  RICH_TEXT = 'wysiwyg.viewer.components.WRichText',
}

enum FORM_ELEMENT_ROLES {
  ROLE_FORM = 'form_Role',
  ROLE_SUBMIT_BUTTON = 'button_Role',
  ROLE_PREVIOUS_BUTTON = 'button_previous_Role',
  ROLE_NEXT_BUTTON = 'button_next_Role',
  ROLE_MESSAGE = 'message_Role',
  ROLE_DOWNLOAD_MESSAGE = 'download_message_Role',
  ROLE_LIMIT_MESSAGE = 'limit_message_Role',
  ROLE_TITLE = 'title_Role',
  ROLE_SUBTITLE = 'subtitle_Role',
  STEP_ROLE = 'step_Role',
  THANK_YOU_STEP_ROLE = 'thank_you_step_Role',
  LIMIT_SUBMISSIONS_STEP_ROLE = 'limit_submissions_step_Role',
  AUTOFILL_MEMBER_EMAIL_ROLE = 'autofill-form-info-role',
}

const INPUT_FIELDS_PREFIX = 'field_role_';

function debug(...args: unknown[]) {
  // eslint-disable-next-line no-console
  console.log('migration >', ...args);
}

type RefsByType = Partial<Record<FORM_ELEMENT_TYPES, ComponentRef[]>>;
type RefsByRole = Partial<Record<FORM_ELEMENT_ROLES, ComponentRef[]>>;

export async function run(sdk: EditorSDK, widgetRef?: ComponentRef) {
  const selectedForm = await getSelectedForm(sdk);
  if (!selectedForm) {
    return;
  }

  if (selectedForm.type === STATE_BOX_WIDGET_TYPE) {
    const { elementRefsByType, elementRefsByRole } = await getStateBoxElements(
      sdk,
      selectedForm.componentRef,
    );

    const style = await remapStyles(
      sdk,
      elementRefsByType,
      elementRefsByRole,
      selectedForm.componentRef,
    );

    if (!widgetRef) {
      debug('failed to add widget');
      return;
    }

    await updateWidgetStyle(sdk, widgetRef, style);
    return;
  }

  if (selectedForm.type === OLD_FORMS_WIDGET_TYPE) {
    const elementRefsByType = await getElementRefsByType(
      sdk,
      selectedForm.componentRef,
    );
    const elementRefsByRole = await getElementRefsByRole(
      sdk,
      selectedForm.componentRef,
    );

    if (!elementRefsByType || !elementRefsByRole) {
      debug('failed to resolve element data');
      return;
    }

    const style = await remapStyles(
      sdk,
      elementRefsByType,
      elementRefsByRole,
      selectedForm.componentRef,
    );

    if (!widgetRef) {
      debug('failed to add widget');
      return;
    }

    await updateWidgetStyle(sdk, widgetRef, style);
  }

  debug('done');
}

async function getSelectedForm(sdk: EditorSDK) {
  const selectedRefs = await sdk.editor.selection.getSelectedComponents(TOKEN);
  if (!selectedRefs?.length) {
    debug('no elements are selected');
    return;
  }
  let selectedRef: ComponentRef | undefined = selectedRefs[0];
  let refType = await sdk.components.getType(TOKEN, {
    componentRef: selectedRef,
  });

  if (refType === OLD_FORMS_APP_WIDGET_TYPE) {
    const formRef = await findChildWithType(
      sdk,
      selectedRef,
      OLD_FORMS_WIDGET_TYPE,
    );

    const stateBoxRef = await findChildWithType(
      sdk,
      selectedRef,
      STATE_BOX_WIDGET_TYPE,
    );

    if (!(formRef || stateBoxRef)) {
      debug('selected element does not contain any form');
      return;
    }
    selectedRef = formRef ? formRef : stateBoxRef;
    refType = formRef ? OLD_FORMS_WIDGET_TYPE : STATE_BOX_WIDGET_TYPE;
  }

  if (
    !(refType === STATE_BOX_WIDGET_TYPE || refType === OLD_FORMS_WIDGET_TYPE)
  ) {
    debug('selected element is not a form', refType);
    return;
  }

  if (!selectedRef) {
    debug('selected ref is empty');
    return;
  }

  return { componentRef: selectedRef, type: refType };
}

async function remapStyles(
  sdk: EditorSDK,
  refsByType: RefsByType,
  refsByRole: RefsByRole,
  formRef: ComponentRef,
) {
  const formContainerStyle = await getFormContainerStyle(sdk, formRef);

  const textInputRef = refsByType[FORM_ELEMENT_TYPES.TEXT_INPUT]?.[0];
  const textFieldRef = refsByRole['field_role_text' as FORM_ELEMENT_ROLES]?.[0];
  const textInputStyle = textFieldRef
    ? await getTextInputStyle(sdk, textFieldRef)
    : textInputRef
    ? await getTextInputStyle(sdk, textInputRef)
    : { properties: {}, propertiesSource: {} };

  const buttonRef = refsByType[FORM_ELEMENT_TYPES.BUTTON]?.[0];
  const submitButtonRef =
    refsByRole[FORM_ELEMENT_ROLES.ROLE_SUBMIT_BUTTON]?.[0];
  const buttonStyle = submitButtonRef
    ? await getButtonStyle(sdk, submitButtonRef)
    : buttonRef
    ? await getButtonStyle(sdk, buttonRef)
    : { properties: {}, propertiesSource: {} };

  const nextButtonRef = refsByRole[FORM_ELEMENT_ROLES.ROLE_NEXT_BUTTON]?.[0];
  const nextButtonStyle = nextButtonRef
    ? await getNextButtonStyle(sdk, nextButtonRef)
    : { properties: {}, propertiesSource: {} };

  const previousButtonRef =
    refsByRole[FORM_ELEMENT_ROLES.ROLE_PREVIOUS_BUTTON]?.[0];
  const previousButtonStyle = previousButtonRef
    ? await getPreviousButtonStyle(sdk, previousButtonRef)
    : { properties: {}, propertiesSource: {} };

  const titleRef = refsByRole[FORM_ELEMENT_ROLES.ROLE_TITLE]?.[0];
  const subtitleRef = refsByRole[FORM_ELEMENT_ROLES.ROLE_SUBTITLE]?.[0];
  const titleStyle = titleRef
    ? await getRichTextStyle(sdk, titleRef)
    : subtitleRef
    ? await getRichTextStyle(sdk, subtitleRef)
    : { properties: {}, propertiesSource: {} };

  const style: Style = {
    properties: {
      ...formContainerStyle?.properties,
      ...textInputStyle?.properties,
      ...buttonStyle?.properties,
      ...nextButtonStyle?.properties,
      ...previousButtonStyle?.properties,
      ...titleStyle?.properties,
    },
    propertiesSource: {
      ...formContainerStyle?.propertiesSource,
      ...textInputStyle?.propertiesSource,
      ...buttonStyle?.propertiesSource,
      ...nextButtonStyle?.propertiesSource,
      ...previousButtonStyle?.propertiesSource,
      ...titleStyle?.propertiesSource,
    },
  };

  debug('merged styles', style);

  return style;
}

export async function getFormRefs(sdk: EditorSDK) {
  const formRefs = await sdk.components.findAllByType(TOKEN, {
    componentType: OLD_FORMS_WIDGET_TYPE,
  });

  if (!formRefs?.length) {
    debug('forms not found');
    return [];
  }

  debug('found forms', formRefs);

  return formRefs;
}

export async function getStateBoxRefs(sdk: EditorSDK) {
  const stateBoxRefs = await sdk.components.findAllByType(TOKEN, {
    componentType: STATE_BOX_WIDGET_TYPE,
  });

  const stateBoxFormRefs: ComponentRef[] = [];

  for (const stateBoxRef of stateBoxRefs) {
    const children = await sdk.components.getChildren(TOKEN, {
      componentRef: stateBoxRef,
      recursive: false,
    });
    for (const child of children ?? []) {
      const type = await sdk.components.getType(TOKEN, { componentRef: child });
      if (type === STATE_BOX_FORM_WIDGET_TYPE) {
        const role = await getRole(sdk, child);
        if (role === FORM_ELEMENT_ROLES.STEP_ROLE) {
          stateBoxFormRefs.push(stateBoxRef);
          break;
        }
      }
    }
  }

  if (!stateBoxFormRefs?.length) {
    debug('state-box forms not found');
    return [];
  }

  debug('found state-box forms', stateBoxFormRefs);

  return stateBoxFormRefs;
}

async function getRole(sdk: EditorSDK, componentRef: ComponentRef) {
  const connectionItems = await sdk.controllers.listConnections(TOKEN, {
    componentRef,
  });
  const role = connectionItems.find(
    (connectionItem) => connectionItem.type === 'ConnectionItem',
  )?.role;

  return role;
}

async function getStateBoxElements(sdk: EditorSDK, stateBoxRef: ComponentRef) {
  const TAG = `stateBoxRef: ${stateBoxRef.id} >`;

  const childrenRefs = await sdk.components.getChildren(TOKEN, {
    componentRef: stateBoxRef,
    recursive: false,
  });

  const allRefsByType: RefsByType = {};
  const allRefsByRole: RefsByRole = {};

  for (const childRef of childrenRefs ?? []) {
    const type = await sdk.components.getType(TOKEN, {
      componentRef: childRef,
    });
    const role = await getRole(sdk, childRef);
    if (
      type === STATE_BOX_FORM_WIDGET_TYPE &&
      role === FORM_ELEMENT_ROLES.STEP_ROLE
    ) {
      const refsByType = (await getElementRefsByType(sdk, childRef)) ?? {};
      const refsByRole = (await getElementRefsByRole(sdk, childRef)) ?? {};

      for (const _elementType in refsByType) {
        const elementType = _elementType as FORM_ELEMENT_TYPES;
        allRefsByType[elementType] = [
          ...(allRefsByType[elementType] ?? []),
          ...(refsByType[elementType] ?? []),
        ];
      }

      for (const _elementRole in refsByRole) {
        const elementRole = _elementRole as FORM_ELEMENT_ROLES;
        allRefsByRole[elementRole] = [
          ...(allRefsByRole[elementRole] ?? []),
          ...(refsByRole[elementRole] ?? []),
        ];
      }
    }
  }

  const result = {
    elementRefsByType: allRefsByType,
    elementRefsByRole: allRefsByRole,
  };
  debug(TAG, 'resolved state-box elements', result);
  return result;
}

async function getElementRefsByType(sdk: EditorSDK, formRef: ComponentRef) {
  const TAG = `formRef: ${formRef.id} >`;

  const childrenRefs = await sdk.components.getChildren(TOKEN, {
    componentRef: formRef,
    recursive: true,
  });

  if (!childrenRefs?.length) {
    debug(TAG, 'form elements not found');
    return;
  }

  debug(TAG, 'found form elements', childrenRefs);

  const children = await Promise.all(
    childrenRefs.map(async (childRef) => {
      const childType = await sdk.components.getType(TOKEN, {
        componentRef: childRef,
      });

      return { childRef, childType };
    }),
  );

  debug(TAG, 'resolved element types', children);

  const elementRefsByType = children.reduce<RefsByType>((byType, child) => {
    const { childType, childRef } = child;

    if (
      Object.values(FORM_ELEMENT_TYPES).includes(
        childType as FORM_ELEMENT_TYPES,
      )
    ) {
      const elementType = childType as FORM_ELEMENT_TYPES;
      if (!byType[elementType]) {
        byType[elementType] = [];
      }
      byType[elementType]!.push(childRef);
    }

    return byType;
  }, {});

  debug(TAG, 'filtered elements with relevant types', elementRefsByType);

  return elementRefsByType;
}

async function getElementRefsByRole(sdk: EditorSDK, formRef: ComponentRef) {
  const TAG = `formRef: ${formRef.id} >`;

  const childrenRefs = await sdk.components.getChildren(TOKEN, {
    componentRef: formRef,
    recursive: true,
  });

  if (!childrenRefs?.length) {
    debug(TAG, 'form elements not found');
    return;
  }

  debug(TAG, 'found form elements', childrenRefs);

  const mappedConnections = await Promise.all(
    childrenRefs.map(async (childRef) => {
      const connectionItems = await sdk.controllers.listConnections(TOKEN, {
        componentRef: childRef,
      });

      return { childRef, connectionItems };
    }),
  );

  debug(TAG, 'resolved element connections', mappedConnections);

  const elementRefsByRole = mappedConnections.reduce<RefsByRole>(
    (byRole, mappedConnection) => {
      const { connectionItems, childRef } = mappedConnection;

      const item = connectionItems.find(
        (connectionItem) => connectionItem.type === 'ConnectionItem',
      );
      if (!item) {
        return byRole;
      }

      if (
        Object.values(FORM_ELEMENT_ROLES).includes(
          item.role as FORM_ELEMENT_ROLES,
        ) ||
        item.role.startsWith(INPUT_FIELDS_PREFIX)
      ) {
        const elementRole = item.role as FORM_ELEMENT_ROLES;
        if (!byRole[elementRole]) {
          byRole[elementRole] = [];
        }
        byRole[elementRole]!.push(childRef);
      }

      return byRole;
    },
    {},
  );

  debug(TAG, 'filtered elements with relevant roles', elementRefsByRole);

  return elementRefsByRole;
}

export async function addFormWidget(sdk: EditorSDK, formRef: ComponentRef) {
  const TAG = `formRef: ${formRef.id} >`;

  const pageRef = await sdk.components.getPage(TOKEN, {
    componentRef: formRef,
  });

  if (!pageRef) {
    debug(TAG, 'form page not found');
    return;
  }

  debug(TAG, 'resolved form page', pageRef);

  const widget = await sdk.tpa.add.component(TOKEN, {
    managingAppDefId: NEW_FORMS_APP_DEF_ID,
    componentType: sdk.document.tpa.TPAComponentType.Widget,
    appDefinitionId: NEW_FORMS_APP_DEF_ID,
    widget: {
      widgetId: NEW_FOMRS_WIDGET_ID,
      wixPageId: pageRef.id,
    },
  });

  if (!widget?.compId) {
    debug(TAG, 'failed to add widget');
    return;
  }

  const widgetRef: ComponentRef = { id: widget.compId, type: 'DESKTOP' };

  debug(TAG, 'widget was added', widgetRef);

  return widgetRef;
}

async function updateWidgetStyle(
  sdk: EditorSDK,
  widgetRef: ComponentRef,
  style: Style,
) {
  const TAG = `widgetRef: ${widgetRef.id} >`;
  debug(TAG, 'updating style', style);
  await sdk.components.style.updateFull(TOKEN, {
    componentRef: widgetRef,
    style: {
      type: 'ComponentStyle',
      styleType: 'custom',
      style,
      componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
      skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
    },
  });
  debug(TAG, 'style update finished');
}

async function findChildWithType(
  sdk: EditorSDK,
  componentRef: ComponentRef,
  type: string,
) {
  const TAG = `searching for children in ${componentRef.id} with type ${type} >`;

  const childrenRefs = await sdk.components.getChildren(TOKEN, {
    componentRef,
    recursive: true,
  });

  if (!childrenRefs?.length) {
    debug(TAG, 'no children found');
    return;
  }

  debug(TAG, 'found children', childrenRefs);

  const children = await Promise.all(
    childrenRefs.map(async (childRef) => {
      const childType = await sdk.components.getType(TOKEN, {
        componentRef: childRef,
      });

      return { childRef, childType };
    }),
  );

  debug(TAG, 'resolved children types', children);

  const childRef = children.find((child) => child.childType === type)?.childRef;

  if (!childRef) {
    debug(TAG, 'no children with specified type');
  }

  debug(TAG, 'found child with specified type', childRef);

  return childRef;
}
