import {
  installEcomPlatform,
  addComponents,
  EcomComponent,
} from '@wix/ecom-platform-sdk';
import { type EditorSDK } from '@wix/editor-platform-sdk-types';
import type { FlowAPI, IHttpClient } from '@wix/yoshi-flow-editor';
import { queryForms } from '@wix/ambassador-forms-v4-form/http';
import { ValidationFormat } from '@wix/ambassador-forms-v4-form/types';
import { NAMESPACE } from '../constants/namespace';
import type { ErrorHandlerPublicAPI } from '@wix/fe-essentials-editor/error-handler';

export const ECOM_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';

async function isEcomPageInstalled(
  pageId: EcomComponent,
  sdk: EditorSDK,
): Promise<boolean> {
  const allSitePages = await sdk.pages.data.getAll('');
  return !!allSitePages.find(
    (page) => page.tpaPageId === pageId && page.appDefinitionId === ECOM_DEF_ID,
  );
}

async function isEcomInstalled(sdk: EditorSDK): Promise<boolean> {
  return sdk.document.application.isApplicationInstalled(ECOM_DEF_ID, {
    appDefinitionId: ECOM_DEF_ID,
  });
}

type InstallEcomParams = {
  sdk: EditorSDK;
  flowApi: FlowAPI;
};

export async function maybeInstallEcom(params: InstallEcomParams) {
  const {
    flowApi: { httpClient, reportError, errorHandler },
  } = params;

  try {
    const hasFormsWithTransactions = !!(
      await fetchFormsWithTransactions(httpClient, errorHandler)
    )?.length;

    if (hasFormsWithTransactions) {
      await installEcomPages(params);
    }
  } catch (e: unknown) {
    reportError(e as Error | string);
  }
}

export async function installEcomPages({
  sdk,
  flowApi: { reportError },
}: InstallEcomParams) {
  try {
    const ecomCheckoutInstalled = await isEcomPageInstalled(
      EcomComponent.CHECKOUT,
      sdk,
    );
    const ecomThankYouPageInstalled = await isEcomPageInstalled(
      EcomComponent.THANK_YOU_PAGE,
      sdk,
    );

    const ecomInstalled = await isEcomInstalled(sdk);

    if (!ecomInstalled) {
      await installEcomPlatform(sdk);
    }

    if (!ecomCheckoutInstalled) {
      await addComponents(sdk, [EcomComponent.CHECKOUT]);
    }

    if (!ecomThankYouPageInstalled) {
      await addComponents(sdk, [EcomComponent.THANK_YOU_PAGE]);
    }
  } catch (e: unknown) {
    reportError(e as Error | string);
  }
}

async function fetchFormsWithTransactions(
  httpClient: IHttpClient,
  errorHandler: ErrorHandlerPublicAPI,
) {
  try {
    const response = await errorHandler.withErrorHandler(
      () =>
        httpClient?.request(
          queryForms({
            query: {
              filter: {
                'fields.validation.predefined.format': {
                  $hasSome: ValidationFormat.PAYMENT,
                },
                namespace: { $eq: NAMESPACE },
              },
              cursorPaging: {
                limit: 1,
              },
            },
          }),
        ),
      { errorCodesMap: {} },
    );
    return response.data.forms;
  } catch (e) {
    const resolvedError = errorHandler.getResolvedError(e);
    console.error(resolvedError);
    return [];
  }
}
