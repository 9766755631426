import type { ComponentRef, EditorSDK } from '@wix/editor-platform-sdk-types';
import {
  adjustFontSize,
  type Color,
  type Font,
  FONT_PRESET_NAME_BY_KEY,
  type FontMap,
  type FontObj,
  type FontOverrides,
  type FontPresetKey,
  hexToRgb,
  type Style,
} from './style-utils';

type RichTextData = {
  text: string | undefined;
};

type FontData = {
  fontSize: string | undefined;
  fontFamily: string | undefined;
  color: string | undefined;
  isBold: boolean | undefined;
  isItalic: boolean | undefined;
  hasUnderline: boolean | undefined;
  fontKey: FontPresetKey | undefined;
  colorKey: string | undefined;
};

type TextFieldStyle = {
  colors: {
    headerOneColor: Color;
    headerTwoColor: Color;
  };
  fonts: {
    headerOneFont: Font;
    headerTwoFont: Font;
  };
};

const TOKEN = 'TOKEN';

function debug(...args: unknown[]) {
  // eslint-disable-next-line no-console
  console.log('migration >', ...args);
}

export async function getRichTextStyle(
  sdk: EditorSDK,
  richTextRef: ComponentRef,
) {
  const TAG = `richTextRef: ${richTextRef.id} >`;
  const richTextData = (await sdk.components.data.get(TOKEN, {
    componentRef: richTextRef,
  })) as RichTextData;

  if (!richTextData) {
    debug(TAG, 'rich text data not found');
    return;
  }

  debug(TAG, 'resolved rich text data', richTextData);

  const fontMap: FontMap = await sdk.theme.fonts.getMap(TOKEN);
  const colors = await sdk.theme.colors.getAll(TOKEN);

  // eslint-disable-next-line no-console
  console.log({ fontMap, colors });

  if (!richTextData.text) {
    debug(TAG, 'missing styles');
    return;
  }

  const fontData: FontData = {
    fontSize: richTextData.text.match(/font-size:(\d+px)/)?.[1],
    fontFamily: richTextData.text.match(/font-family:([^,]+),?.*"/)?.[1],
    color: richTextData.text.match(/color:(#.{6})/)?.[1],
    colorKey: richTextData.text.match(/class="(color_[^"]+)"/)?.[1],
    isBold: richTextData.text.includes('font-weight:bold') ? true : undefined,
    isItalic: richTextData.text.includes('font-style:italic')
      ? true
      : undefined,
    hasUnderline: richTextData.text.includes('text-decoration:underline')
      ? true
      : undefined,
    fontKey: richTextData.text.match(
      /class="(font_[^"]+)"/,
    )?.[1] as FontPresetKey,
  };

  debug(TAG, 'extracted font data from html', fontData);

  const toColor = (
    hexColor: string | undefined,
    colorKey: string | undefined,
  ): Color => {
    const value = colorKey || hexColor;
    const valueSource = colorKey ? 'theme' : 'value';
    return value
      ? { value, valueSource, alpha: 1, alphaSource: 'value' }
      : undefined;
  };

  const toFont = (font: FontData, overrides?: FontOverrides): Font => {
    const preset = font.fontKey && fontMap[font.fontKey];

    if (!font.fontFamily && !preset) {
      return undefined;
    }

    const getIsCustom = () => {
      if (!preset) {
        return true;
      }

      if (
        (font.fontFamily === preset.family || !font.fontFamily) &&
        (font.fontSize === preset.size || !font.fontSize) &&
        preset.bold === Boolean(font.isBold) &&
        preset.italic === Boolean(font.isItalic)
      ) {
        return false;
      }

      return true;
    };

    const isCustom = getIsCustom();

    const fontObj = {
      size: font.fontSize ?? preset?.size,
      family: font.fontFamily ?? preset?.family,
      bold: font.isBold ?? preset?.bold,
      italic: font.isItalic ?? preset?.italic,
    } as FontObj;

    const { font: adjustedFont, fontKey: adjustedFontKey } = adjustFontSize(
      {
        font: isCustom ? fontObj : preset!,
        fontSource: isCustom ? 'value' : 'theme',
        fontKey: isCustom ? undefined : font.fontKey,
        fontMap,
      },
      overrides?.sizeOffset,
    );

    const presetName =
      adjustedFontKey && !isCustom
        ? FONT_PRESET_NAME_BY_KEY[
            adjustedFontKey as keyof typeof FONT_PRESET_NAME_BY_KEY
          ]
        : 'Custom';

    const value = {
      style: {
        bold: Boolean(
          typeof overrides?.bold !== 'undefined'
            ? overrides?.bold
            : adjustedFont.bold,
        ),
        italic: Boolean(
          typeof overrides?.italic !== 'undefined'
            ? overrides?.italic
            : adjustedFont.italic,
        ),
        underline: isCustom ? Boolean(font.hasUnderline) : false,
      },
      size: adjustedFont.size,
      preset: presetName,
      family: adjustedFont.family.toLowerCase(),
      fontStyleParam: true,
    };

    const valueSource = presetName === 'Custom' ? 'value' : 'theme';

    return { value: JSON.stringify(value), valueSource };
  };

  const textStyle: TextFieldStyle = {
    colors: {
      headerOneColor: toColor(fontData.color, fontData.colorKey),
      headerTwoColor: toColor(fontData.color, fontData.colorKey),
    },
    fonts: {
      headerOneFont: toFont(fontData),
      headerTwoFont: toFont(fontData, { sizeOffset: -1 }),
    },
  };

  const colorStyle = Object.entries(textStyle.colors).reduce<Style>(
    (remapped, entry) => {
      const [key, color] = entry;

      if (typeof color !== 'undefined') {
        const value =
          color.valueSource === 'theme'
            ? color.value
            : hexToRgb(color.value, color.alpha);

        remapped.properties[`param_color_${key}`] = value;
        remapped.propertiesSource[`param_color_${key}`] = color.valueSource;
        remapped.properties[`alpha-param_color_${key}`] = color.alpha;
        remapped.propertiesSource[`alpha-param_color_${key}`] =
          color.alphaSource;
      }

      return remapped;
    },
    { properties: {}, propertiesSource: {} },
  );

  const fontStyle = Object.entries(textStyle.fonts).reduce<Style>(
    (remapped, entry) => {
      const [key, font] = entry;

      if (typeof font !== 'undefined') {
        remapped.properties[`param_font_${key}`] = font.value;
        remapped.propertiesSource[`param_font_${key}`] = font.valueSource;
      }

      return remapped;
    },
    { properties: {}, propertiesSource: {} },
  );

  const remappedStyle = {
    properties: {
      ...colorStyle.properties,
      ...fontStyle.properties,
    },
    propertiesSource: {
      ...colorStyle.propertiesSource,
      ...fontStyle.propertiesSource,
    },
  };

  debug(TAG, 'remapped rich text style', remappedStyle);

  return remappedStyle;
}
