import { getIsInBlogSlot, addWidgetToSlot, addWidgetToEditor } from './utils';
import type { EditorSDK } from '@wix/editor-platform-sdk-types';
import type { OwnerLogger, FlowAPI } from '@wix/yoshi-flow-editor';
import { reportPublishedWidgets, reportDeletedWidget } from './bi';
import { maybeInstallEcom } from './ecom';
import { maybeInstallScheduling } from './scheduling';

interface OnWidgetAddedParams {
  sdk: EditorSDK;
  appToken: string;
  flowApi: FlowAPI;
}

export const onWidgetAdded = ({
  sdk,
  appToken,
  flowApi,
}: OnWidgetAddedParams) =>
  sdk.addEventListener('widgetAdded', async (props) => {
    const {
      detail: { componentRef, originalComponentId },
    } = props;

    const component = await sdk.document.components.serialize(appToken, {
      componentRef,
    });

    const isInBlogSlot = await getIsInBlogSlot(appToken, sdk, component);
    if (isInBlogSlot) {
      await addWidgetToSlot({
        appToken,
        sdk,
        componentRef,
        component,
        flowApi,
      });
    } else {
      await addWidgetToEditor({
        appToken,
        sdk,
        componentRef,
        component,
        flowApi,
        originalComponentId,
      });
    }
  });

interface OnComponentDeletedParams {
  sdk: EditorSDK;
  bi?: OwnerLogger;
  appToken: string;
}

export const onComponentDeleted = ({
  sdk,
  bi,
  appToken,
}: OnComponentDeletedParams) =>
  sdk.addEventListener(
    'componentDeleted',
    async ({ detail: { componentRef } }) => {
      const msid = await sdk.info.getMetaSiteId(appToken);
      reportDeletedWidget({ componentRef, bi, msid });
    },
  );

interface OnSitePublishedParams {
  sdk: EditorSDK;
  appToken: string;
  bi?: OwnerLogger;
}

export const onSitePublished = ({ sdk, appToken, bi }: OnSitePublishedParams) =>
  sdk.addEventListener('siteWasPublished', async () => {
    await reportPublishedWidgets({ sdk, appToken, bi });
  });

interface OnAppVisitedInDashboardParams {
  sdk: EditorSDK;
  flowApi: FlowAPI;
}

export const onAppVisitedInDashboard = ({
  sdk,
  flowApi,
}: OnAppVisitedInDashboardParams) => {
  sdk.addEventListener('appVisitedInDashboard', async () => {
    await Promise.all([
      maybeInstallEcom({ sdk, flowApi }),
      maybeInstallScheduling({ sdk, flowApi }),
    ]);
  });
};
