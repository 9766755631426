import type { EditorSDK } from '@wix/editor-platform-sdk-types';

export type FontMap = Awaited<
  ReturnType<EditorSDK['theme']['fonts']['getMap']>
>;

export type Style = {
  properties: Record<string, string | number | boolean>;
  propertiesSource: Record<string, string>;
};

export type ColorOverrides = { opacity?: number };
export type Color =
  | {
      value: string;
      valueSource: string;
      alpha: number;
      alphaSource: string;
      overrides?: ColorOverrides;
    }
  | undefined;

export type FontOverrides = {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  sizeOffset?: number;
};
export type Font =
  | { value: string; valueSource: string; overrides?: FontOverrides }
  | undefined;

export type StyleMap = {
  colors?: Record<string, Color>;
  fonts?: Record<string, Font>;
  numbers?: Record<string, number | undefined>;
  booleans?: Record<string, boolean | undefined>;
};

export type BoxShadow = {
  horizontalOffset: number;
  verticalOffset: number;
  blurRadius: number;
  spreadRadius: number;
  color: string;
  rgbColor: string;
  hexColor: string;
  r: number;
  g: number;
  b: number;
  a: number;
  distance: number;
  angle: number;
};

export type FontObj = {
  bold: boolean;
  color: string;
  family: string;
  italic: boolean;
  lineHeight: string;
  size: string;
  style: 'normal' | 'italic';
  variant: 'normal' | 'small-caps' | 'inherit';
  weight: 'normal' | 'bold' | 'bolder' | 'lighter';
};

export enum COLORS_CODES {
  BLACK = '#000000',
  WHITE = '#FFFFFF',
}

export const FONT_PRESET_NAME_BY_KEY = {
  font_0: 'Title',
  font_1: 'Menu',
  font_2: 'Page-title',
  font_3: 'Heading-XL',
  font_4: 'Heading-L',
  font_5: 'Heading-M',
  font_6: 'Heading-S',
  font_7: 'Body-L',
  font_8: 'Body-M',
  font_9: 'Body-S',
  font_10: 'Body-XS',
} as const;

export type FontPresetKey = keyof typeof FONT_PRESET_NAME_BY_KEY;

export function createFontObject(
  fontValue: string,
  fontSource: string,
  fontOverrides: FontOverrides | undefined,
  fontMap: FontMap,
) {
  const font =
    fontSource === 'theme' ? fontMap[fontValue] : parseFontStr(fontValue);

  if (!font) {
    return;
  }
  const { font: adjustedFont, fontKey: adjustFontKey } = adjustFontSize(
    {
      font,
      fontKey: fontSource === 'theme' ? fontValue : undefined,
      fontSource,
      fontMap,
    },
    fontOverrides?.sizeOffset,
  );

  const presetName = FONT_PRESET_NAME_BY_KEY[adjustFontKey as FontPresetKey];

  const preset = fontSource === 'theme' && presetName ? presetName : 'Custom';

  return {
    style: {
      bold: Boolean(
        typeof fontOverrides?.bold !== 'undefined'
          ? fontOverrides?.bold
          : adjustedFont.bold,
      ),
      italic: Boolean(
        typeof fontOverrides?.italic !== 'undefined'
          ? fontOverrides?.italic
          : adjustedFont.italic,
      ),
      underline: false,
    },
    size: adjustedFont.size,
    preset,
    family: adjustedFont.family.toLowerCase(),
    fontStyleParam: true,
  };
}

export function adjustFontSize(
  fontData: {
    font: FontObj | FontMap[0];
    fontSource: string;
    fontKey: string | undefined;
    fontMap: FontMap;
  },
  sizeOffset: number | undefined,
) {
  const { font, fontKey, fontSource, fontMap } = fontData;

  if (!sizeOffset) {
    return { font, fontKey };
  }

  if (fontSource === 'theme' && fontKey) {
    const fontNumber = fontKey.match(/font_(\d+)/)?.[1];
    if (fontNumber) {
      const fontNumberWithOffset = String(
        parseInt(fontNumber, 10) + sizeOffset,
      );
      const newFontKey = fontKey.replace(fontNumber, fontNumberWithOffset);
      const newFont = fontMap[newFontKey];
      if (newFont) {
        return { font: newFont, fontKey: newFontKey };
      }
    }
  }

  if (fontSource === 'value') {
    const { bigger, smaller } = getSimilarFonts(font as FontObj, fontMap);
    const fontNumber = (sizeOffset > 0 ? bigger : smaller).match(
      /font_(\d+)/,
    )?.[1];

    if (fontNumber) {
      const adjustedOffset = sizeOffset > 0 ? sizeOffset - 1 : sizeOffset + 1;
      const fontNumberWithOffset = String(
        parseInt(fontNumber, 10) + adjustedOffset,
      );

      const newFontKey = `font_${fontNumberWithOffset}`;
      const newFont = fontMap[newFontKey];
      if (newFont) {
        return { font: { ...font, size: newFont.size }, fontKey: undefined };
      }
    }
  }

  return { font, fontKey };
}

export function getSimilarFonts(font: FontObj, fontMap: FontMap) {
  return Object.keys(fontMap).reduce<{
    bigger: keyof FontMap;
    smaller: keyof FontMap;
  }>(
    (result, fontKey) => {
      const fontPreset = fontMap[fontKey];
      const presetSize = parseInt(fontPreset.size, 10);
      const fontSize = parseInt(font.size, 10);
      const biggerSize = parseInt(fontMap[result.bigger].size, 10);
      const smallerSize = parseInt(fontMap[result.smaller].size, 10);

      if (presetSize > fontSize && presetSize <= biggerSize) {
        result.bigger = fontKey;
      }

      if (presetSize < fontSize && presetSize >= smallerSize) {
        result.smaller = fontKey;
      }
      return result;
    },
    { bigger: 'font_0', smaller: 'font_10' },
  );
}

export function parseFontStr(fontStr: string): FontObj {
  const split = fontStr.split(' ');
  const sizeSplit = split[3] ? split[3].split('/') : [];
  return {
    style: split[0] as FontObj['style'],
    variant: split[1] as FontObj['variant'],
    weight: split[2] as FontObj['weight'],
    size: sizeSplit[0],
    lineHeight: sizeSplit[1],
    family: split[4].replace(/\+/g, ' '),
    color: split[5],
    bold: split[2] === 'bold' || parseInt(split[2], 10) >= 700,
    italic: split[0] === 'italic',
  };
}

export function hexToRgb(hex: string, alpha: number) {
  hex = hex.replace(/^#/, '');

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  if (alpha === 1) {
    return `rgb(${r}, ${g}, ${b})`;
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function parseBoxShadow(shadow: string): BoxShadow | undefined {
  const parts = shadow.trim().split(/\s+/);

  if (parts.length < 5) {
    return undefined;
  }

  const horizontalOffset = parseFloat(parts[0]);
  const verticalOffset = parseFloat(parts[1]);
  const blurRadius = parseFloat(parts[2]);
  const spreadRadius = parseFloat(parts[3]);

  const color = parts.slice(4).join(' ');
  const rgbaMatch = color.match(
    /rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)?\)/,
  );
  if (!rgbaMatch) {
    return undefined;
  }

  const r = parseInt(rgbaMatch[1], 10);
  const g = parseInt(rgbaMatch[2], 10);
  const b = parseInt(rgbaMatch[3], 10);
  const a = rgbaMatch[4] !== undefined ? parseFloat(rgbaMatch[4]) : 1;

  const rgbColor = `rgb(${r}, ${g}, ${b})`;
  const hexColor = rgbToHex(r, g, b).toUpperCase();

  const distance = Math.sqrt(horizontalOffset ** 2 + verticalOffset ** 2);

  const degreesPerRadians = 180 / Math.PI;
  const radiansAngle = Math.atan2(horizontalOffset, -verticalOffset);
  const numericAngle = parseFloat(
    ((360 + radiansAngle * degreesPerRadians) % 360).toFixed(0),
  );

  return {
    horizontalOffset,
    verticalOffset,
    blurRadius,
    spreadRadius,
    color,
    rgbColor,
    hexColor,
    r,
    g,
    b,
    a,
    distance,
    angle: numericAngle,
  };
}

function rgbToHex(r: number, g: number, b: number) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function componentToHex(value: number) {
  const hex = value.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

export function colorsToStyle(colors: Record<string, Color>): Style {
  return Object.entries(colors).reduce<Style>(
    (remapped, entry) => {
      const [key, color] = entry;

      if (typeof color !== 'undefined') {
        const value =
          color.valueSource === 'theme'
            ? color.value
            : hexToRgb(color.value, color.alpha);

        remapped.properties[`param_color_${key}`] = value;
        remapped.propertiesSource[`param_color_${key}`] = color.valueSource;
        remapped.properties[`alpha-param_color_${key}`] = color.alpha;
        remapped.propertiesSource[`alpha-param_color_${key}`] =
          color.alphaSource;
      }

      return remapped;
    },
    { properties: {}, propertiesSource: {} },
  );
}

export function fontsToStyle(
  fonts: Record<string, Font>,
  fontMap: FontMap,
): Style {
  return Object.entries(fonts).reduce<Style>(
    (remapped, entry) => {
      const [key, font] = entry;

      if (typeof font !== 'undefined') {
        const fontObject = createFontObject(
          font.value,
          font.valueSource,
          font.overrides,
          fontMap,
        );
        if (typeof fontObject !== 'undefined') {
          remapped.properties[`param_font_${key}`] = JSON.stringify(fontObject);
          remapped.propertiesSource[`param_font_${key}`] = font.valueSource;
        }
      }

      return remapped;
    },
    { properties: {}, propertiesSource: {} },
  );
}

export function numbersToStyle(numbers: Record<string, number | undefined>) {
  return Object.entries(numbers).reduce<Style>(
    (remapped, entry) => {
      const [key, value] = entry;

      if (typeof value !== 'undefined') {
        remapped.properties[`param_number_${key}`] = value;
        remapped.propertiesSource[`param_number_${key}`] = 'value';
      }

      return remapped;
    },
    { properties: {}, propertiesSource: {} },
  );
}

export function booleansToStyle(booleans: Record<string, boolean | undefined>) {
  return Object.entries(booleans).reduce<Style>(
    (remapped, entry) => {
      const [key, value] = entry;

      if (typeof value !== 'undefined') {
        remapped.properties[`param_number_${key}`] = value;
        remapped.propertiesSource[`param_number_${key}`] = 'value';
      }

      return remapped;
    },
    { properties: {}, propertiesSource: {} },
  );
}

export function convertStyles(styleMap: StyleMap, fontMap: FontMap): Style {
  const colors = styleMap.colors && colorsToStyle(styleMap.colors);
  const fonts = styleMap.fonts && fontsToStyle(styleMap.fonts, fontMap);
  const numbers = styleMap.numbers && numbersToStyle(styleMap.numbers);
  const booleans = styleMap.booleans && booleansToStyle(styleMap.booleans);

  const style = {
    properties: {
      ...colors?.properties,
      ...fonts?.properties,
      ...numbers?.properties,
      ...booleans?.properties,
    },
    propertiesSource: {
      ...colors?.propertiesSource,
      ...fonts?.propertiesSource,
      ...numbers?.propertiesSource,
      ...booleans?.propertiesSource,
    },
  };

  return style;
}

export function extractBorderRadius(radius: string | undefined) {
  if (!radius) {
    return;
  }

  const [topLeft, topRight, bottomRight, bottomLeft] = radius.split(' ');
  const value = bottomLeft ?? bottomRight ?? topLeft ?? topRight;
  if (!value) {
    return;
  }

  return parseInt(value, 10);
}
