import type { ComponentRef, EditorSDK } from '@wix/editor-platform-sdk-types';
import {
  type Color,
  COLORS_CODES,
  convertStyles,
  extractBorderRadius,
  type Font,
  type FontMap,
} from './style-utils';

type ButtonStyle = {
  'alpha-bg': string | undefined;
  'alpha-bgh': string | undefined;
  'alpha-brd': string | undefined;
  'alpha-brdh': string | undefined;
  'alpha-txt': string | undefined;
  'alpha-txth': string | undefined;
  bg: string | undefined;
  bgh: string | undefined;
  'boxShadowToggleOn-shd': string | undefined;
  brd: string | undefined;
  brdh: string | undefined;
  brw: string | undefined;
  fnt: string | undefined;
  rd: string | undefined;
  shd: string | undefined;
  txt: string | undefined;
  txth: string | undefined;
  bgd: string | undefined;
  txtd: string | undefined;
};

enum ButtonFieldBorderStyle {
  Rectangle = 0,
  RectangleRoundedCorners = 1,
  RectangleFilled = 2,
  RectangleRoundedCornersFilled = 3,
  ABC = 4, // Next/Previous buttons do not have this option
}

type NextButtonFieldStyle = {
  colors: {
    nextButtonColor: Color;
    nextButtonColorHover: Color;
    nextButtonBackgroundColor: Color;
    nextButtonBackgroundColorHover: Color;
    nextButtonBorderColor: Color;
    nextButtonBorderColorHover: Color;
  };
  fonts: {
    nextButtonFont: Font;
  };
  numbers: {
    nextButtonStyle: number | undefined;
    nextButtonBorderWidth: number | undefined;
    nextButtonBorderRadius: number | undefined;
  };
};

const TOKEN = 'TOKEN';

function debug(...args: unknown[]) {
  // eslint-disable-next-line no-console
  console.log('migration >', ...args);
}

export async function getNextButtonStyle(
  sdk: EditorSDK,
  buttonRef: ComponentRef,
) {
  const TAG = `buttonRef: ${buttonRef.id} >`;
  const buttonStyle = await sdk.components.style.get(TOKEN, {
    componentRef: buttonRef,
  });

  if (!buttonStyle) {
    debug(TAG, 'button style not found');
  }

  debug(TAG, 'resolved button style', buttonStyle);

  const fontMap: FontMap = await sdk.theme.fonts.getMap(TOKEN);

  const properties: ButtonStyle = buttonStyle.style.properties;
  const propertiesSource: ButtonStyle = buttonStyle.style.propertiesSource;

  const toColor = (
    name: keyof ButtonStyle,
    alphaOverride?: number,
    fallback?: string,
  ): Color => {
    const value = properties[name];
    const valueSource = propertiesSource[name] ?? 'value';

    const alphaName = `alpha-${name}` as keyof ButtonStyle;
    const alpha = parseFloat(properties[alphaName] ?? '1');
    const alphaSource = propertiesSource[alphaName] ?? 'value';

    return value
      ? { value, valueSource, alpha: alphaOverride ?? alpha, alphaSource }
      : fallback
      ? {
          value: fallback,
          valueSource: 'value',
          alpha: alphaOverride ?? alpha,
          alphaSource: 'value',
        }
      : undefined;
  };

  const toFont = (name: keyof ButtonStyle): Font => {
    const value = properties[name];
    const valueSource = propertiesSource[name] ?? 'value';

    return value && valueSource ? { value, valueSource } : undefined;
  };

  const getIsTextButton = () => {
    const hasBorder =
      properties.brw && parseInt(properties.brw, 10) && toColor('brd');
    if (hasBorder) {
      return false;
    }

    const backgroundColor = toColor('bg');
    const hasBackground = backgroundColor && backgroundColor.alpha > 0;
    if (hasBackground) {
      return false;
    }

    return true;
  };

  const getIsBorderButton = () => {
    const backgroundColor = toColor('bg');
    const hasBackground = backgroundColor && backgroundColor.alpha > 0;
    if (hasBackground) {
      return false;
    }

    const hasBorder =
      properties.brw && parseInt(properties.brw, 10) > 0 && toColor('brd');
    if (hasBorder) {
      return true;
    }

    return false;
  };

  const isTextButton = getIsTextButton();
  const isBorderButton = getIsBorderButton();

  const styleMap: NextButtonFieldStyle = {
    colors: {
      nextButtonColor: toColor('txt'),
      nextButtonColorHover: toColor('txth'),
      nextButtonBackgroundColor: isTextButton
        ? toColor('bg', 0, COLORS_CODES.WHITE)
        : toColor('bg'),
      nextButtonBackgroundColorHover: isTextButton
        ? toColor('bgh', 0, COLORS_CODES.WHITE)
        : toColor('bgh'),
      nextButtonBorderColor: isTextButton
        ? toColor('brd', 0, COLORS_CODES.BLACK)
        : toColor('brd'),
      nextButtonBorderColorHover: isTextButton
        ? toColor('brdh', 0, COLORS_CODES.BLACK)
        : toColor('brdh'),
    },
    fonts: {
      nextButtonFont: toFont('fnt'),
    },
    numbers: {
      nextButtonStyle:
        isTextButton || isBorderButton
          ? ButtonFieldBorderStyle.Rectangle
          : ButtonFieldBorderStyle.RectangleFilled,
      nextButtonBorderWidth: isTextButton
        ? 0
        : properties.brw
        ? parseInt(properties.brw, 10)
        : 0,
      nextButtonBorderRadius: extractBorderRadius(properties.rd),
    },
  };

  const remappedStyle = convertStyles(styleMap, fontMap);

  debug(TAG, 'remapped button style', remappedStyle);

  return remappedStyle;
}
